/* --------------------------
---------- Header -----------
-------------------------- */
	var header = document.getElementById('main-header');
	var headroom = new Headroom(header,{
		offset: 88,
		tolerance: 15,
		onPin: function(){
			$('aside#blog').removeClass('pinned');
		},
		onUnpin: function(){
			$('aside#blog').addClass('pinned');	
		}
	});
	headroom.init();

/* --------------------------
---------- Collapse ---------
-------------------------- */
	$('[data-toggle="collapse"]').click(function(){
		$(this).siblings('.collapse').collapse('toggle');
	});

/* --------------------------
------ scrollTop links -----
-------------------------- */
	// $('a[href^="#"]').click(function(e){
	// 	e.preventDefault();
	// 	var href = $(this).attr('href').substring(1);
	// 	console.log(href);
	// 	var target = document.getElementById( href );
	// 	$('html').animate({
	// 		scrollTop: target.offsetTop
	// 	}, 450);
	// });

/* --------------------------
---------- Jivochat -----------
-------------------------- */
	$('[data-chat="open"]').click(function(e){
		e.preventDefault();
		jivo_api.open()
	});

/* --------------------------
---------- SlickJS -----------
-------------------------- */
	$('.slick').slick({
		dots: true,
		dotsClass: 'slick-dots',
		arrows: false,
		slidesToShow: 5,
		slidesToScroll: 5,
		responsive: [
		 	{
		      breakpoint: 1200,
		      settings: {
		        slidesToShow: 4,
		        slidesToScroll: 4,
		        infinite: true,
		        dots: true
		      }
		    },
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 3,
		        infinite: true,
		        dots: true
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		      	autoplay: true,
		      	dots: 1,
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		    // You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
	  ]
	});

/* --------------------------
---------- ShuffleJS -----------
-------------------------- */
	var elem = document.querySelector('#shuffle');
	if( elem ){
		// setup
			var Shuffle = window.Shuffle;
			var Demo = function(element){
				this.element = element;
				this.shuffle = new Shuffle(element,{ itemSelector: '.item' });
			}

			Demo.prototype.addSort = function(val){
				function sortByOrder(element) {
				   return element.getAttribute('data-order');
				}
			    this.shuffle.sort({ by:sortByOrder });
			}

			var shuffleInstance = new Demo(elem);

		// actual sort
			$('.shuffle-sort').click(function(){
				// change data-order
					var clicked = $(this);
					var sortBy = clicked.data('group');
					if( sortBy !== 'todos' ){
						$('.item').each(function(){
							if( $(this).data('group') === sortBy ){
								$(this).attr('data-order',0).removeClass('dimmed');
							} else {
								$(this).attr('data-order',1).addClass('dimmed');
							}
						});
					} else {
						$('.item').removeClass('dimmed');
					}
				// sort
					shuffleInstance.addSort();

				// classes
					clicked.addClass('btn-primary').removeClass('btn-outline-primary');
					clicked.siblings().addClass('btn-outline-primary').removeClass('btn-primary');
			});
	}

/* --------------------------
--------- Count Up ----------
-------------------------- */

	$(window).on('scroll',function(){
		$('.countup').each(function(){
			var number = $(this).data('number');
			number = ( number < 10 ) ? '0'+number : number;
			if( ($(window).scrollTop() + $(window).outerHeight()) >= ($(this).offset().top + 30) && $(this).data('counted') !== true ){
				$(this).countUp({
					last: number,
					duration: 600
				});
				$(this).data('counted',true);
			}
		});
	});

/* --------------------------
- Animate: Flechas (nosotros) -
-------------------------- */

	$(window).on('scroll',function(){
		$('#flechas-nosotros svg').each(function(){
			if( ($(window).scrollTop() + $(window).outerHeight()) > ($(this).offset().top + $(this).outerHeight()*0.8) ){
				$('#Shape1,#Fade1').addClass('open');
			} else if( ($(window).scrollTop() + $(window).outerHeight()) > ($(this).offset().top + $(this).outerHeight()*0.55) ) {
				$('#Shape2,#Fade2').addClass('open');
			}
		});
	});

/* --------------------------
------ Form submission ------
-------------------------- */

	function revealMessage(msg,form){
		form.find('.form-message').html(msg).addClass('open');
        setTimeout(function() {
        	form.find('.form-message').html('').removeClass('open');
        }, 5000);
	}

	$('.contact-form').submit(function(e) {
		// Prevent the form from actually submitting
		e.preventDefault();
		
		var form = $(this);

		// Get the post data
		var data = $(this).serialize();

		// Send it to the server
		$.post('/', data, function(response) {
	        if (response.success) {
	        	ga('send', {'hitType': 'pageview', 'page': '/formulario-llamada-enviado', 'title': 'Formulario llamada (Se confirmó que se envió el formulario)'});
	            revealMessage('Gracias por contactarnos, te contactaremos cuanto antes.',form);
	        } else {
	            revealMessage('Hubo un error, intenta de nuevo más tarde.',form);
	        }
	    });
	});